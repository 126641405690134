import React, {useState, useEffect} from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledExternalLink } from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import { Token } from '@extendswap/sdk'
import { ButtonPrimary } from '../Button'
// import { useColor } from '../../hooks/useColor'
import { Break, CardNoise, CardBGImage } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'

import { Contract } from '@ethersproject/contracts'

import farmingABI from './farmingABI.json'

import { useActiveWeb3React } from '../../hooks'
import BigNumber from 'bignumber.js'

import { formatUnits } from '@ethersproject/units'

import useFetch from "react-fetch-hook"

// import useSWR from 'swr'

// import { useWeb3React } from '@web3-react/core'
// import { isAddress } from 'utils'
//import useUSDCPrice from '../../utils/useUSDCPrice'
// import { BIG_INT_SECONDS_IN_WEEK } from '../../constants'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%, ${showBackground ? theme.black : theme.bg5} 100%) `};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;
  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export function PendingRewards({
  pending
} : {
  pending: any
}) {
  console.log("yesss" + pending)
  if (!pending) {
    return (<span>?</span>);
  } else {
    return (<span>{pending}</span>);
  }
}

export function getPendingRewards(account: any, library: any, farm: any) {
  const contract = new Contract(farm, farmingABI, library.getSigner()) // Contract address, contract ABI, library
  var p = new Promise(async resolve => {
    resolve(await contract.pending(0, account))
  });
  p.then(function(v: any) {
    console.log(v.toNumber())
    return(v.toNumber())
  })
}

export const getBalanceNumber = (balance: BigNumber, decimals = 9) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toNumber()
}

export default function PoolCard({
  bgColor, token0, token1, reward, externalUrl, deposit, poolRate, farmAddress
}: {
  token0: Token;
  token1: Token;
  reward: string;
  externalUrl: string;
  deposit: string;
  poolRate: string;
  farmAddress: string;
  bgColor: string;
}) {
  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)
  // const { account, library } = useWeb3React()
  // get the color of the token
  //const token = currency0 === ETHER ? token1 : token0
  // const WETH = currency0 === ETHER ? token0 : token1
  //const backgroundColor = useColor(token)

  const perWeek = Number(poolRate) * 201600 // blocks in one week

  const { account, library } = useActiveWeb3React()

  const [pendingRewards, setPendingRewards] = useState("Loading...")
  // const [bgPendingRewards, bgSetPendingRewards] = useState(new BigNumber)

  useEffect(() => {
    const contract = new Contract(farmAddress, farmingABI, library?.getSigner())
    var p = new Promise(resolve => {
      resolve(contract.pending(0, account))
    });
    p.then(function(v: any) {
      console.log(v)
      // bgSetPendingRewards(v)
      setPendingRewards(parseFloat(formatUnits(v, 9)).toPrecision(3))
    })
    return function cleanup() {
      console.log('hi')
    };
  });

  let contractAddress = ''

  const tokenNameUpperCase = reward

  if (tokenNameUpperCase === 'EC') {
    contractAddress = '0x400df62C3052fDBaad8C74C0226147F9bFaaF7eC'
  } else if (tokenNameUpperCase === 'EXF') {
    contractAddress = '0x6306e883493824Ccf606D90E25F68a28e47b98a3'
  } else if (tokenNameUpperCase === 'EXFELIX') {
    contractAddress = '0x529D5fE5A9A3FdcDa1E74f6FbF4388Addaf71bB0'
  }

  const dataurl = "https://api-extendswap.vercel.app/api/tokens/" + (contractAddress)

  const { data }: any = useFetch(
    dataurl
  );

  const numberPrice: number = data && data.data.price
  const usdValue = Number(pendingRewards) * numberPrice
  const usdBalance = usdValue.toFixed(3)

  // const contract = new Contract('0xd7972C022176bE2093503087f5d069e706700306', farmingABI, library.getSigner())
  // console.log(contract)
  // const earned = contract.pending(0, account)
  // console.log()

  // const earned = getEarned()

  // get the USD value of staked WETH
  // const USDPrice = useUSDCPrice(WETH)
  // const valueOfTotalStakedAmountInUSDC =
  //  valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  // const pending = getPendingRewards(account, library, farmAddress)
  // console.log("hiii" + pending)

  return (
    <Wrapper showBackground={true} bgColor={bgColor}>
      <CardBGImage desaturate />
      <CardNoise />

      <TopSection>
        <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
        <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '8px' }}>
          {reward} - {deposit} LP
        </TYPE.white>

        <StyledExternalLink href={externalUrl} style={{ width: '100%' }}>
          <ButtonPrimary padding="8px" borderRadius="8px">
            Manage <span> ↗</span>
          </ButtonPrimary>
        </StyledExternalLink>
      </TopSection>

      <StatContainer>
        {/*<RowBetween>
          <TYPE.white> Total deposited</TYPE.white>
          <TYPE.white>
            Total USD value: 1
          </TYPE.white>
        </RowBetween>*/}
        <RowBetween>
          <TYPE.white> Pool rate </TYPE.white>
          <TYPE.white>
            {poolRate} {reward} / block ({perWeek} {reward} / week)
          </TYPE.white>
        </RowBetween>
      </StatContainer>

      {/* TODO: Change this to pool rate*/}

        <>
          <Break />
          <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Your pending rewards</span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span>
              {pendingRewards} {reward} ({usdBalance} USD)
            </TYPE.black>
          </BottomSection>
        </>
    </Wrapper>
  )
}
