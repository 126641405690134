import React from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { TYPE, ExternalLink } from '../../theme'
import PoolCard from '../../components/earn/PoolCard'
import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'

import { Token, ChainId } from '@extendswap/sdk'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

const DataRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
flex-direction: column;
`};
`

const poolList = [
  {
    reward: "EXF",
    deposit: "BUSD/BNB",
    poolRate: "1.25",
    farmAddress: "0x8cf9AAea21E200664AD20D2EF0C4896fF34f273F",
    token0: new Token(ChainId.MAINNET, '0xe9e7cea3dedca5984780bafc599bd69add087d56', 18), // BUSD
    token1: new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18), // BNB
    bgColor: "#FFA900",
    externalUrl: "https://farm.extendswap.com/farms/BUSD-BNB%20LP%20(EXF)"
  },
  {
    reward: "EC",
    deposit: "BUSD/BNB",
    poolRate: "0.037",
    farmAddress: "0xa68f29161ABE7CA08869aB61DC0C00E23B6eF88c",
    token0: new Token(ChainId.MAINNET, '0xe9e7cea3dedca5984780bafc599bd69add087d56', 18), // BNB
    token1: new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18), // BUSD
    bgColor: "#FFA900",
    externalUrl: "https://farm.extendswap.com/farms/BUSD-BNB%20LP%20(EC)"
  },
  {
    reward: "EXFelix",
    deposit: "BUSD/BNB",
    poolRate: "0.001",
    farmAddress: "0x90AB9d640812850943D705A30843Add6052B1165",
    token0: new Token(ChainId.MAINNET, '0xe9e7cea3dedca5984780bafc599bd69add087d56', 18), // BNB
    token1: new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18), // BUSD
    bgColor: "#FFA900",
    externalUrl: "https://farm.extendswap.com/farms/BUSD-BNB%20LP%20(EXFelix)"
  },
  {
    reward: "EXF",
    deposit: "BNB/EXF",
    poolRate: "1.25",
    farmAddress: "0xdcDf41706FF891B6c452eA461df7F08555D4c8e1",
    token0: new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18), // BNB
    token1: new Token(ChainId.MAINNET, '0x6306e883493824ccf606d90e25f68a28e47b98a3', 18), // EXF
    bgColor: "#FFA900",
    externalUrl: "https://farm.extendswap.com/farms/BNB-EXF%20LP"
  },
  {
    reward: "EXF",
    deposit: "BUSD/EXF",
    poolRate: "1.25",
    farmAddress: "0xe11Aacdc74bF9a0A8B21Ff0b7258Ae879CcfCC9C",
    token0: new Token(ChainId.MAINNET, '0xe9e7cea3dedca5984780bafc599bd69add087d56', 18), // BUSD
    token1: new Token(ChainId.MAINNET, '0x6306e883493824ccf606d90e25f68a28e47b98a3', 18), // EXF
    bgColor: "#FFA900",
    externalUrl: "https://farm.extendswap.com/farms/BUSD-EXF%20LP"
  },
  {
    reward: "EC",
    deposit: "BNB/EC",
    poolRate: "0.037",
    farmAddress: "0x89B1E98BFa38140C8F50fc22073606415E25ed62",
    token0: new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18), // BNB
    token1: new Token(ChainId.MAINNET, '0x400df62c3052fdbaad8c74c0226147f9bfaaf7ec', 18), // EC
    bgColor: "#FFA900",
    externalUrl: "https://farm.extendswap.com/farms/BNB-EC%20LP"
  },
  {
    reward: "EC",
    deposit: "BUSD/EC",
    poolRate: "0.037",
    farmAddress: "0xecB1B1b438739bebf3CC6DADd1C47aC51bD4aF33",
    token0: new Token(ChainId.MAINNET, '0xe9e7cea3dedca5984780bafc599bd69add087d56', 18), // BUSD
    token1: new Token(ChainId.MAINNET, '0x400df62c3052fdbaad8c74c0226147f9bfaaf7ec', 18), // EC
    bgColor: "#FFA900",
    externalUrl: "https://farm.extendswap.com/farms/BUSD-EC%20LP"
  },
  {
    reward: "EXFelix",
    deposit: "BNB/EXFelix",
    poolRate: "0.001",
    farmAddress: "0xe8718Fc368a93345A4ab54e3700BcBb7Ccac54eb",
    token0: new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18), // BNB
    token1: new Token(ChainId.MAINNET, '0x529d5fe5a9a3fdcda1e74f6fbf4388addaf71bb0', 18), // EXFelix
    bgColor: "#FFA900",
    externalUrl: "https://farm.extendswap.com/farms/BNB-EXFelix%20LP"
  }
]

export default function Earn() {

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <DataCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>ExtendSwap liquidity farming</TYPE.white>
              </RowBetween>
              <RowBetween>
                <TYPE.white fontSize={14}>
                  Deposit your Liquidity Provider tokens to receive EXF, EC & EXFelix.
                </TYPE.white>
              </RowBetween>{' '}
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </DataCard>
      </TopSection>

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        <DataRow style={{ alignItems: 'baseline' }}>
          <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Active pools</TYPE.mediumHeader>
          {/*<Countdown exactEnd={stakingInfos?.[0]?.periodFinish} />*/}
          <TYPE.black fontWeight={400}>
            Total Pools: {poolList.length}
          </TYPE.black>
        </DataRow>

        <PoolSection>
          {
            poolList.map(d => (
            <PoolCard
              token0={d.token0}
              token1={d.token1}
              bgColor={d.bgColor}
              reward={d.reward}
              externalUrl={d.externalUrl}
              deposit={d.deposit}
              poolRate={d.poolRate}
              farmAddress={d.farmAddress}
              />
              ))
          }
        </PoolSection>
      </AutoColumn>
      <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          If you need help, you're welcome to join our <ExternalLink href="https://t.me/extendswap">Telegram Group ↗</ExternalLink>
        </TYPE.main>
    </PageWrapper>
  )
}
