import React from 'react'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { DoughInfoWrapper } from '../../components/swap/styleds'
// import styled from 'styled-components'
import DoughInfoHeader from '../../components/swap/DoughInfoHeader'

import { BlueCard, OutlineCard } from '../../components/Card'

import { ButtonPrimary, ButtonBlue } from '../../components/Button'

import { TYPE } from '../../theme'

import DoughInfoAppBody from '../DoughInfoAppBody'

import { RouteComponentProps } from 'react-router-dom'

import chef from '../../assets/images/chef.png'

/*const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`*/

export default function DoughInfo({ history }: RouteComponentProps) {
  return (
    <>
      <DoughInfoAppBody>
        <DoughInfoHeader />
        <DoughInfoWrapper>
          <AutoColumn gap={'md'}>
            <AutoColumn justify="space-between">
              <AutoRow justify={'center'} style={{ padding: '0 1rem' }}>
                  <AutoRow justify={'center'} style={{ padding: '0 1rem' }}>
                  <img alt={"$DOUGH token logo"} src={chef} height="85" />
                  </AutoRow>
                  <TYPE.mediumHeader>$DOUGH Tokenomic Details</TYPE.mediumHeader>
                  <p style={{ textAlign: 'center' }}>
                      <TYPE.main>Initial Total Supply: 5,550,000 tokens</TYPE.main>
                      <TYPE.main>Buy/Sell Tax: 9%</TYPE.main>
                </p>
                  <TYPE.main style={{ padding: '15px', textAlign: 'center' }}>All of the tax is used to buy BNB and reflect it back to users holding $DOUGH.</TYPE.main>
              </AutoRow>
            </AutoColumn>
          </AutoColumn>
          <ColumnCenter>
            <BlueCard>
                <AutoColumn gap="10px">
                    <AutoRow justify={'center'} style={{ padding: '0 1rem' }}>
                        <TYPE.link fontWeight={400} color={'primaryText1'}>
                            <b>Tip:</b> If you hold any more than 1,500 $DOUGH tokens, you will earn rewards in BNB,
                            paid directly to your BSC wallet.
                        </TYPE.link>
                    </AutoRow>
                </AutoColumn>
            </BlueCard>
            <OutlineCard style={{ marginTop: '10px'}}>
                <AutoRow justify={'center'}>
                    <TYPE.mediumHeader>
                        But why $DOUGH?
                    </TYPE.mediumHeader>
                    <TYPE.main>
                        <ul>
                            <li>$DOUGH will enable ExtendSwap to generate much more volume on ExtendSwap.
                        It will also help us get more token launches, more LP pools and media exposure.</li>
                        <li>With increased volume on ExtendSwap, our investors share these rewards. 4% of fees are
                        distributed amongst EXF holders, and 5% amongst EC holders.
                        </li>
                        </ul>
                    </TYPE.main>
                </AutoRow>
            </OutlineCard>
            <OutlineCard style={{ marginTop: '10px'}}>
                <AutoRow justify={'center'}>
                    <TYPE.mediumHeader>
                        Farm $DOUGH
                    </TYPE.mediumHeader>
                    <TYPE.main>
                        <ol>
                            <li>At the top of this page, you can see a button that says "Pool". Click on this.</li>
                            <li>Click on add liquidity and fill out the information required.</li>
                            <li>Click on the "Farm $DOUGH" button just below. Find the pool you just entered and deposit your tokens</li>
                            <li>That's it! You're now earning $DOUGH every 3 seconds. You can see your earnings accumulate.</li>
                        </ol>
                    </TYPE.main>
                </AutoRow>
            </OutlineCard>
        </ColumnCenter>
        <a style={{ textDecoration: 'none' }} href="/#/swap?outputCurrency=0xD3Fa2A4fd73495F9eBf6ba333891E94cA1eCb36c">
            <ButtonPrimary style={{ marginTop: '15px' }}>Buy $DOUGH</ButtonPrimary>
        </a>
        <a style={{ textDecoration: 'none' }} href="https://doughfarm.extendswap.com">
        <ButtonPrimary style={{ marginTop: '8px' }}>Farm $DOUGH</ButtonPrimary>
        </a>
            <a target="blank" href="https://twitter.com/intent/tweet?text=$DOUGH%20BUY,%20FARM,%20EARN%20BNB%20by%20holding%20the%20LP%20farming%20reward%20token%20that%20keeps%20PAYING%20in%20BNB%20(https://extendswap.com/%23/dough)%20only%20on%20%23EXTENDSWAP" style={{ textDecoration: 'none'}}>
                <ButtonBlue style={{ marginTop: '8px'}}>Tweet about $DOUGH</ButtonBlue>
            </a>
        </DoughInfoWrapper>
      </DoughInfoAppBody>
    </>
  )
}
